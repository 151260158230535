<template>
  <div>
    <gl-title title="VIP购买记录"></gl-title>
    <!-- <div style="margin: 18px;">
      <el-button type="primary" size="mini"> 导出 </el-button>
    </div> -->
    <!-- <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="itemId" label="会员昵称">
          <gl-list-search
            v-model.trim="mFormData.itemId"
            placeholder="会员昵称"
            @enter="mGetList"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="itemStatus" label="套餐类型">
          <gl-option
            v-model="mFormData.itemStatus"
            :list="setMealType"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="消费时间">
          <gl-date-picker
            :ref="mDateRef"
            :startTime.sync="mFormData.listtingTimeStart"
            :endTime.sync="mFormData.listtingTimeEnd"
          ></gl-date-picker>
        </el-form-item>
        <el-form-item prop="startTime" label="到期时间">
          <gl-date-picker
            :ref="mDateRef"
            :startTime.sync="mFormData.listtingTimeStart"
            :endTime.sync="mFormData.listtingTimeEnd"
          ></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header> -->
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="vipName" label="VIP套餐"></el-table-column>
        <el-table-column prop="vipPrice" label="支付金额"></el-table-column>
        <el-table-column prop="userName" label="购买用户昵称"></el-table-column>
        <el-table-column prop="createDate" label="购买日期"></el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      ></gl-pagination>
    </gl-card>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import { vipBuyRecordList } from '@/api/vip'
export default {
  mixins: [ListMixin],
  data(){
    return{
      setMealType: [
        {name: 'SVIP年卡', value: '1'},
        {name: 'VIP季卡', value: '2'},
      ],
    }
  },
  mounted(){
    this.mGetListFun = vipBuyRecordList
    this.mGetList()
  },
}
</script>

<style>

</style>